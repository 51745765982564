export const getLocalTimezone = (): string => {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// example - return 2020-10-05T00:00:00
export const getLocalDateTimeStartOfDay = (date: Date) => {
  const addZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}T00:00:00`;
};

export const getDateTimeFromLocalDateTime = (localDateTime: string) => {
  return new Date(localDateTime);
};

export const formatSelectedDateToViewFormat = (
  selectedDate: string,
  dateRegionalSettingsLocale?: string,
) => {
  const date = new Date(selectedDate);
  const formatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

export const formatRfcTimeStringToViewFormat = (
  rfcTime: string,
  locale?: string,
) => {
  const date = new Date(rfcTime);
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatter(date, formatOptions, locale).toLowerCase();
};

export const formatShortDate = (dateString: string, locale?: string) => {
  return formatter(
    new Date(dateString),
    {
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
    locale,
  );
};

export const formatRfcTimeStringToDateAndTime = (
  rfcTime: string,
  dateRegionalSettingsLocale: string,
) => {
  const formattedDate = formatDate({
    rfcTime,
    dateRegionalSettingsLocale,
  });
  const formattedTime = formatTime({
    rfcTime,
    dateRegionalSettingsLocale,
  });
  return `${formattedDate}, ${formattedTime}`;
};

const formatDate = ({
  rfcTime,
  dateRegionalSettingsLocale,
}: {
  rfcTime: string;
  dateRegionalSettingsLocale?: string;
}) => {
  const date = new Date(rfcTime);
  const formatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

const formatTime = ({
  rfcTime,
  dateRegionalSettingsLocale,
}: {
  rfcTime: string;
  dateRegionalSettingsLocale?: string;
}) => {
  const date = new Date(rfcTime);
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

const formatter = (
  date: Date,
  formatOptions: Intl.DateTimeFormatOptions,
  dateRegionalSettingsLocale?: string,
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    dateRegionalSettingsLocale,
    formatOptions,
  );
  return dateTimeFormat.format(date);
};

export const minutesDifferencesBetweenTwoDates = (
  rfcStartTime: string,
  rfcEndTime: string,
) => {
  const secondsInDay = 86400;
  const minutesInHour = 60;
  const hoursInDay = 24;
  const delta =
    Math.abs(
      new Date(rfcStartTime).getTime() - new Date(rfcEndTime).getTime(),
    ) / 1000;

  const days = Math.floor(delta / secondsInDay);
  const hours =
    Math.floor(delta / (minutesInHour * minutesInHour)) % hoursInDay;
  const minutes = Math.floor(delta / minutesInHour) % minutesInHour;

  return minutes + hours * minutesInHour + days * hoursInDay;
};

export function formatTimezone(
  selectedDate: string,
  timezone: string,
  locale: string,
): string {
  const offset =
    Intl.DateTimeFormat(locale, {
      year: 'numeric',
      timeZoneName: 'short',
      timeZone: timezone,
    })
      .formatToParts(new Date(selectedDate.slice(0, 19)))
      .find((part) => part.type === 'timeZoneName')?.value ?? '';
  const timezoneName =
    Intl.DateTimeFormat(locale, {
      year: 'numeric',
      timeZoneName: 'long',
      timeZone: timezone,
    })
      .formatToParts(new Date(selectedDate.slice(0, 19)))
      .find((part) => part.type === 'timeZoneName')?.value ?? '';
  return `${timezoneName} (${offset})`;
}

export function getTodayLocalDateTimeStartOfDay(timezone: string): string {
  const dateTimeFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
  const dateString = dateTimeFormat.format(new Date());
  const shiftedDate = new Date(dateString);

  return getLocalDateTimeStartOfDay(shiftedDate);
}
