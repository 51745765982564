export const widgetDefaults = {
  pageName: 'booking_calendar_widget',
};

export enum CalendarErrors {
  SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION = 'SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION = 'SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER = 'SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER',
  SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR = 'SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR',
  RESCHEDULE_SERVER_ERROR = 'RESCHEDULE_SERVER_ERROR',
}

export enum SetError {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum Selectables {
  LOCATION = 'location',
  DURATION = 'duration',
  STAFF_MEMBER = 'staffMember',
}

export enum ErrorMessages {
  NO_SERVICE_AVAILABILITY = 'NO_SERVICE_AVAILABILITY',
}

export enum WidgetComponents {
  TIME_PICKER = 'TIME_PICKER',
  DATE_PICKER = 'DATE_PICKER',
  BOOKING_DETAILS = 'BOOKING_DETAILS',
}

export enum WidgetElements {
  TIME_SLOT = 'TIME_SLOT',
  MONTH_ARROW = 'MONTH_ARROW',
  DATE_IN_MONTH = 'DATE_IN_MONTH',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
}

export enum TriggeredByOptions {
  INITIALIZE_WIDGET = 'INITIALIZE_WIDGET',
  TIMEZONE_CHANGED = 'TIMEZONE_CHANGED',
  DATE_SELECTED = 'DATE_SELECTED',
  MONTH_SELECTED = 'MONTH_SELECTED',
  BOOKING_DETAILS_CLEAR_BUTTON = 'BOOKING_DETAILS_CLEAR_BUTTON',
  BOOKING_DETAILS_STAFF_MEMBER_SELECTABLE = 'BOOKING_DETAILS_STAFF_MEMBER_SELECTABLE',
  BOOKING_DETAILS_LOCATION_SELECTABLE = 'BOOKING_DETAILS_LOCATION_SELECTABLE',
  BOOKING_DETAILS_DURATION_SELECTABLE = 'BOOKING_DETAILS_DURATION_SELECTABLE',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
  TIME_SELECTED = 'TIME_SELECTED',
  SUBMIT = 'SUBMIT',
  FILTER_CHANGED = 'FILTER_CHANGED',
}

export enum InitializeCalendarDateOptions {
  FIRST_AVAILABLE_DATE = 'FIRST_AVAILABLE_DATE',
  TODAY = 'TODAY',
}

export type TimeSlotsAvailability = {
  AVAILABLE: number;
  FULL: number;
  TOO_LATE_TO_BOOK: number;
  TOO_EARLY_TO_BOOK: number;
};

export const BOOKINGS_CALENDAR_REFERRAL_INFO = 'booking_calendar_widget';
