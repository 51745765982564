import React from 'react';
import { CalendarActions } from './actions';

export const CalendarActionsContext = React.createContext<CalendarActions>(
  {} as CalendarActions,
);

export const CalendarActionsConsumer = CalendarActionsContext.Consumer;
export const CalendarActionsProvider = CalendarActionsContext.Provider;

export const useCalendarActions = () =>
  React.useContext(CalendarActionsContext);
