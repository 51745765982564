import React from 'react';
import { st, classes } from './NoAvailableSlots.st.css';
import { AccessibilityHtmlTags } from '../../../../../utils/AccessibilityHtmlTags.const';
import { Text } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { useEnvironment } from 'yoshi-flow-editor';
import { NoAvailableSlotsViewModel } from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { NoAvailableSlotsDataHooks } from './NoAvailableSlotsDataHooks';
import { useCalendarActions } from '../../../Actions/useCalendarActions';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<NoAvailableSlotsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const { noSlotsText, goToNextAvailableLinkText } = viewModel;
  const { goToNextAvailableDate } = useCalendarActions();

  return (
    <div className={st(classes.root, { isMobile })}>
      <div
        data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS_WRAPPER}
        className={st(classes.noAvailableSlotsWrapper)}
      >
        <Text
          data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS}
          className={st(classes.noAvailableSlots)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {noSlotsText}
        </Text>
        <TextButton
          data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS_LINK}
          className={classes.nextAvailableDateLink}
          onClick={() => goToNextAvailableDate()}
        >
          {goToNextAvailableLinkText}
        </TextButton>
      </div>
    </div>
  );
};

export default NoAvailableSlots;
