import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getLocalDateTimeStartOfDay } from '../../../../utils/dateAndTime/dateAndTime';
import {
  FirstDayOfWeek,
  getFirstDayOfTheWeek,
} from '../../../../utils/dateAndTime/weekStart';

export enum DateAvailabilityStatus {
  HAS_AVAILABLE_SLOTS = 'has_available_slots',
  HAS_UNAVAILABLE_SLOTS = 'has_slots',
}

export type DatePickerViewModel = {
  selectedDate: string;
  dateAvailabilityStatuses: Map<string, DateAvailabilityStatus>;
  firstDayOfTheWeek: FirstDayOfWeek;
};

export function createDatePickerViewModel({
  state,
  context: { businessInfo },
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): DatePickerViewModel {
  const { selectedDate, dateAvailability } = state;
  const dateAvailabilityStatuses = new Map<string, DateAvailabilityStatus>();
  dateAvailability?.availabilityEntries?.forEach((dateAvailabilityResponse) => {
    dateAvailabilityStatuses.set(
      getLocalDateTimeStartOfDay(dateAvailabilityResponse.date!),
      dateAvailabilityResponse.hasBookableSlots
        ? DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
        : DateAvailabilityStatus.HAS_UNAVAILABLE_SLOTS,
    );
  });
  const firstDayOfTheWeek = getFirstDayOfTheWeek(
    businessInfo.dateRegionalSettingsLocale!,
  );
  return {
    selectedDate: selectedDate!,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
  };
}
