import { CalendarErrors } from '../../../../utils/bi/consts';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';

export enum BottomSectionStatus {
  LOADING = 'LOADING',
  NO_SERVICE_AVAILABILITY = 'NO_SERVICE_AVAILABILITY',
  LOADED = 'LOADED',
}

export type WidgetViewModel = {
  status: BottomSectionStatus;
  dateAndTimeSectionHeaderText: string;
  bookingDetailsSectionHeaderText: string;
  notificationText: string;
};

export function createWidgetViewModel({
  state,
  context: { t, settings },
}: ViewModelFactoryParams<CalendarState, CalendarContext>): WidgetViewModel {
  const { calendarErrors } = state;

  const notificationText = getNotificationText(calendarErrors, t);

  return {
    status: state.bottomSectionStatus,
    dateAndTimeSectionHeaderText: settings.get(
      settingsParams.dateAndTimeSectionHeaderText,
    ),
    bookingDetailsSectionHeaderText: settings.get(
      settingsParams.bookingDetailsSectionHeaderText,
    ),
    notificationText,
  };
}

const getNotificationText = (
  calendarErrors: CalendarErrors[],
  t: TFunction,
) => {
  if (
    calendarErrors.includes(
      CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
    )
  ) {
    return t('app.notification.no-time-selected-error.text');
  }
  return '';
};
