import React from 'react';
import { BoxSelection, BoxSize } from 'wix-ui-tpa/BoxSelection';
import {
  TimeSelectionViewModel,
  TimeSlot,
} from '../../../ViewModel/timeSelectionViewModel/timeSelectionViewModel';
import { classes, st } from './TimeSelection.st.css';
import { useCalendarActions } from '../../../Actions/useCalendarActions';
import { Text } from 'wix-ui-tpa/Text';

export type TimeSelectionProps = {
  viewModel: TimeSelectionViewModel;
};

const TimeSelection: React.FC<TimeSelectionProps> = ({ viewModel }) => {
  const { timeSlots } = viewModel;
  const { onTimeSelected } = useCalendarActions();

  return (
    <BoxSelection
      name="time-selection"
      data-hook="time-selection"
      className={classes.boxSelection}
      size={BoxSize.small}
      onChange={({ id }) => onTimeSelected(id)}
    >
      {timeSlots.map((timeSlot: TimeSlot, index: number) => {
        const {
          selected,
          allSlotsAreFull,
          tooLateToBookAllSlots,
          tooEarlyToBookAllSlots,
        } = timeSlot.status;
        const isDisabled =
          allSlotsAreFull || tooLateToBookAllSlots || tooEarlyToBookAllSlots;
        return (
          <BoxSelection.Option
            key={index}
            id={timeSlot.rfcStartTime}
            className={classes.boxSelectionOption}
            aria-label={timeSlot.ariaLabel}
            checked={selected}
            disabled={tooEarlyToBookAllSlots}
            unavailable={allSlotsAreFull || tooLateToBookAllSlots}
          >
            <Text
              data-hook={`time-slot-text-${index}`}
              className={st(classes.boxSelectionText, {
                isDisabled,
              })}
            >
              {timeSlot.formattedStartTime}
            </Text>
          </BoxSelection.Option>
        );
      })}
    </BoxSelection>
  );
};

export default TimeSelection;
