import {
  createSettingsParams,
  SettingsParamType,
} from 'yoshi-flow-editor/tpa-settings';

// eslint-disable-next-line no-shadow
export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
}

export type ISettingsParams = {
  columnAlignment: SettingsParamType.String;
  textAlignment: SettingsParamType.String;
  dateAndTimeSectionHeaderText: SettingsParamType.String;
  bookingDetailsSectionHeaderText: SettingsParamType.String;
  noAvailabilityTitle: SettingsParamType.Text;
  noAvailabilitySubtitle: SettingsParamType.Text;
  headerSubtitleVisibility: SettingsParamType.Boolean;
  headerSubtitleShouldBeServiceTagline: SettingsParamType.Boolean;
  headerSubtitleText: SettingsParamType.Text;
  timePickerNoSlotsText: SettingsParamType.Text;
  timePickerGoToNextAvailableLinkText: SettingsParamType.Text;
  timePickerAllSessionsAreFullNotificationText: SettingsParamType.Text;
  initializeWithFirstAvailableDate: SettingsParamType.Boolean;
  unavailableTimeSlotsVisibility: SettingsParamType.Boolean;
  videoConferenceBadgeText: SettingsParamType.Text;
  bookingDetailsPreferencesHeaderText: SettingsParamType.Text;
  bookingDetailsStaffMemberDropDownText: SettingsParamType.Text;
  bookingDetailsLocationDropDownText: SettingsParamType.Text;
  bookingDetailsDurationDropDownText: SettingsParamType.Text;
  bookingDetailsPricingPlanText: SettingsParamType.Text;
  bookingDetailsClearText: SettingsParamType.Text;
  bookingDetailsBookNowText: SettingsParamType.Text;
  bookingDetailsPendingApprovalText: SettingsParamType.Text;
  bookingDetailsRescheduleText: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  columnAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  dateAndTimeSectionHeaderText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.date-and-time-header'),
  },
  bookingDetailsSectionHeaderText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.booking-details-header'),
  },
  noAvailabilityTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.empty-state.no-availability.title'),
  },
  noAvailabilitySubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.empty-state.no-availability.subtitle'),
  },
  headerSubtitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerSubtitleShouldBeServiceTagline: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerSubtitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.header-subtitle'),
  },
  timePickerNoSlotsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.no-slots-message'),
  },
  timePickerGoToNextAvailableLinkText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.go-to-next-available-day'),
  },
  timePickerAllSessionsAreFullNotificationText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t(
        'app.settings.defaults.time-picker.notifications.all-sessions-are-full',
      ),
  },
  bookingDetailsPreferencesHeaderText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.preferences.title'),
  },
  bookingDetailsStaffMemberDropDownText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.staff-member-selection'),
  },
  bookingDetailsLocationDropDownText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.locations-selection'),
  },
  bookingDetailsDurationDropDownText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.durations-selection'),
  },
  bookingDetailsPricingPlanText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pricing-plan.title'),
  },
  bookingDetailsClearText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.clear.title'),
  },
  bookingDetailsBookNowText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.book-now.text'),
  },
  bookingDetailsRescheduleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.rescheduled-booking.booking-details.cta'),
  },
  bookingDetailsPendingApprovalText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pending-approval.text'),
  },
  unavailableTimeSlotsVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  initializeWithFirstAvailableDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  videoConferenceBadgeText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.video-conference-badge-text'),
  },
});
