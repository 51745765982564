// eslint-disable-next-line no-shadow
export enum BookingDetailsDataHooks {
  BOOKING_DETAILS_SELECTION_WRAPPER = 'booking-details-wrapper',
  SLOT_NAME = 'slot-name',
  SLOT_DETAILS = 'slot-details',
  SLOT_DATE_AND_TIME = 'slot-date-and-time',
  SLOT_PLAN_TYPE = 'slot-plan-type',
  DEVIDER = 'devider',
  SLOT_PREFERENCES_WRAPPER = 'slot-preferences-wrapper',
  SLOT_PREFERENCES_TITLE = 'slot-preferences-title',
  SLOT_CLEAR_TITLE = 'slot-clear-title',
  VIDEO_CONFERENCE_BADGE = 'video-conference-badge',
  BOOK_BUTTON = 'book-button',
  DROPDOWN = 'dropdown',
  TOP_DETAILS = 'top-details',
}
